const regularSortStandard = [
  {
    key: '2066013',
    sorts: ['일임형ISA', '가계여신', '기업여신', '방카슈랑스', '신탁', '예금', '장외파생상품', '퇴직연금', '투자금융', '펀드', '카드'],
  },
  {
    key: '2066015',
    sorts: [],
  },
  {
    key: '2066014',
    sorts: [
      '노란우산공제 판매자격',
      '양매도 ETN 판매자격',
      '[특별자산펀드] KB 원자재 특별자산 자투자신탁(상품-파생형)',
      '[특별자산펀드] KB 북미생산유전 고배당 특별자산 투자신탁(인프라-재간접형)',
      '[특별자산펀드] KB스타 골드 특별자산 투자신탁(금-파생형)',
      '[특별자산펀드] 이스트스프링 미국 뱅크론 특별자산자투자신탁(대출채권)',
      '[특별자산펀드] 프랭클린 미국 금리연동 플러스 특별자산 자투자신탁(대출채권)',
      '[특별자산펀드] 한국투자 미국MLP 특별자산 자투자신탁(오일가스인프라-파생형)',
    ],
  }
]

const learnObjectSortStandard = [
  {
    key: '가계여신',
    sorts: [
      '대출성 상품의 금융소비자 보호 교육',
      '가계대출 완전판매 스크립트 교육'
    ],
    isInclude: true,
  },
  {
    key: '신탁',
    sorts: [
      '2022년 신탁 직무수행교육 제1차시',
      '2022년 신탁 직무수행교육 제2차시'
    ],
    isInclude: true,
  },
  {
    key: '예금',
    sorts: [
      'KB국민UP정기예금 이율 관련 설명의무 이행',
      '예금성 상품의 금융소비자 보호',
    ],
    isInclude: true,
  },
  {
    key: '장외파생상품',
    sorts: [
      '파생상품의 이해 및 금융소비자보호',
      '선물환',
      '이자율스왑',
      '통화스왑',
      '통화옵션',
    ],
    isInclude: true,
  },
  {
    // key: '변액보험 판매자격',
    key: '방카슈랑스 판매자격',
    sorts:[
      // 기본필수
      '변액보험 상품해설',
      '변액보험 판매시 준수사항',
      '변액보험 완전판매 프로세스',
      '변액보험 자산운용',
      // ABL생명
      '(무)보너스주는 변액저축보험Ⅱ',
      // DGB생명
      'HIGHFIVE변액연금보험(무)',
      // KB생명
      '(무)KB국민의 변액연금보험II',
      '(무)KB국민의 변액저축보험',
      '(무)KB ELS변액저축보험(거치형)',
      // 교보생명
      '미리보는내연금 (무) 교보FIRST변액연금보험Ⅱ',
      // 미래에셋생명
      '변액저축보험(무)',
      '(무)시간에투자하는변액연금보험',
      // 삼성 생명
      '에이스변액저축보험(무)',
      // 카디프생명
      '(무)더 쉬운자산관리 ETF변액보험Ⅲ',
      // 하나 생명
      '(무)ETF투자의 정석 변액보험',
      '(무)ELS의 정석 변액저축보험II',
      // 흥국 생명
      '(무)베리굿(VARI-GOOD)변액저축보험',
      // 완전판매
      '방카슈랑스 완전판매'
    ]
  },
  {
    key: '노란우산공제 판매자격',
    sorts: [
      '노란우산 소개 및 주요혜택',
      '노란우산 가입방법',
      '청약거절, 청약철회, 계약취소, 계약무효와 부금납부',
      '계약정보 변경 및 부금통산',
      '공제금 지급과 해약',
      '장려금과 주요 Q&A',
    ]
  },
  {
    key: '[특별자산펀드] KB 북미생산유전 고배당 특별자산 투자신탁(인프라-재간접형)',
    sorts:[
      '북미생산유전 펀드 투자리스크',
      '석유개발사업의 이해',
      '쉽게 풀어보는 Q&A',
      '북미생산유전 펀드 투자포인트',
      '특별자산펀드 관련 법률 주요내용',
      '유전시장 동향(북미사장 중심)',
    ]
  },
  {
    key: '[특별자산펀드] KB스타 골드 특별자산 투자신탁(금-파생형)',
    sorts: [
      'KB 든든한 골드 목표전환 펀드',
      '법률',
      'KB 스타 골드 펀드',
      '투자대상 및 투자 방법',
    ]
  },
  {
    key: '[특별자산펀드] 이스트스프링 미국 뱅크론 특별자산자투자신탁(대출채권)',
    sorts: [
      '이스트스프링미국뱅크론펀드소개',
      '미국뱅크론시장전망및운용사(PPMA)소개',
      '특별자산집합투자기구의이해',
    ]
  },
  {
    key: '[특별자산펀드] 프랭클린 미국 금리연동 플러스 특별자산 자투자신탁(대출채권)',
    sorts: [
      '채권펀드의 이해',
      '프랭클린 미국 금리연동 플러스 펀드 소개',
    ]
  },
  {
    key: '[특별자산펀드] 한국투자 미국MLP 특별자산 자투자신탁(오일가스인프라-파생형)',
    sorts: [
      '투자리스크 유형 및 특별자산펀드 유형',
      '한국투자 미국 MLP 특별자산투자신탁',
      '자본시장과금융투자업에관한법률',
      '투자권유준칙 및 직업윤리',
    ]
  }
];

export const sortCourseItems = (splCrseTyCdDcd, items) => {
  const targetSorts = regularSortStandard.find(x => x.key === splCrseTyCdDcd);
  if (targetSorts && targetSorts.sorts && targetSorts.sorts.length > 0) {
    const isInclude = (splCrseTyCdDcd === '2066013');
    const result = [];
    targetSorts.sorts.forEach(x => {
      const target = isInclude ?
          items.find(y => y.crseNm.indexOf(x) > -1) :
          items.find(y => x === y.crseNm);
      if (target) result.push(target);
    });
    const notIncludeItems = isInclude ? items.filter(x => targetSorts.sorts.filter(y => x.crseNm.indexOf(y) > -1).length === 0) :  items.filter(x => !targetSorts.sorts.includes(x.crseNm));
    if (notIncludeItems.length > 0) result.push(...notIncludeItems);

    // const notIncludeItems = items.filter(x => !targetSorts.sorts.includes(x.objNm));
    // if (notIncludeItems.length > 0) result.push(...notIncludeItems);
    return result;
  }
  return items;
}

export const sortObjectItems = (crseNm, items, isInclude) => {
  const targetSorts = isInclude ? learnObjectSortStandard.find(x => crseNm.includes(x.key) && x.isInclude === true) : learnObjectSortStandard.find(x => x.key === crseNm);

  if(targetSorts) {
    const result = [];
    targetSorts.sorts.forEach(x => {
      const target = items.find(y => x === y.objNm);
      if (target) result.push(target);
    });
    const notIncludeItems = items.filter(x => !targetSorts.sorts.includes(x.objNm));
    if (notIncludeItems.length > 0) result.push(...notIncludeItems);
    return result;
  }
  return items;
}

export const distinctItems = (items, key) => {
  const objs = {};
  items.forEach(x => {
    objs[x[key]] = items
  });
  return Object.keys(objs).map(x => objs[x]);
}

export const sortKeyDesc = (items, key) => {
  return items.sort((a,b) => {if(a[key] > b[key]){ return -1} return 0;});
}
