<template>
  <header class="kb-header" id="kb-header">
    <div v-if="!isSearchAreaShow" class="header-component">
        <!-- header-title -->
        <div class="header-column header-title">
            스타링크 아카데미
        </div>
        <!-- //header-title -->
        <!-- header-util -->
        <div class="header-column header-util right">
          <div class="util util-search">
            <a href="javascript:" @click="isSearchAreaShow = true" class="util-actions util-actions-search">
              <i class="icon-search"></i>
            </a>
          </div>
        </div>
        <!-- //header-util -->
    </div>
    <div v-else class="header-component">
        <!-- header-util -->
        <div class="header-column header-util left">
          <div class="util util-back">
          <a href="javascript:" @click="isSearchAreaShow = false" class="util-actions util-actions-back">
              <i class="icon-history-back"></i>
          </a>
          </div>
          <div class="util util-search-input">
            <input type="text" v-model="search.title" @keyup.enter="getStarLinkFiles" placeholder="자료실 검색">
          </div>
        </div>
        <!-- //header-util -->
        <!-- header-util -->
        <div class="header-column header-util right">
          <div class="util util-search">
            <a href="javascript:" @click="getStarLinkFiles" class="util-actions util-actions-search">
              <i class="icon-search"></i>
            </a>
          </div>
        </div>
        <!-- //header-util -->
    </div>
  </header>
  <main class="kb-main" id="kb-educourse">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="educourse-view-container mb-0">
        <div class="view-contents">
          <!-- content-section:과정안내 -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">연수현황</h4>
            </header>
            <div class="text-item-container">
              <div class="content-item">
                <div class="title mb-2">Lv.0 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <strong class="td-text"><span class="text-muted"> {{ dtlItem && dtlItem.lv0Fnsh ? dtlItem.lv0Fnsh : '-' }}</span></strong></div>
                <div class="title mb-2">Lv.1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <strong class="td-text"><span class="text-muted"> {{ dtlItem && dtlItem.lv1Fnsh ? dtlItem.lv1Fnsh : '-' }}</span></strong></div>
                <div class="title mb-2">Lv.2 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <strong class="td-text"><span class="text-muted"> {{ dtlItem && dtlItem.lv2Fnsh ? dtlItem.lv2Fnsh : '-' }}</span></strong></div>
                <div class="title mb-2">Lv.3 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <strong class="td-text"><span class="text-muted"> {{ dtlItem && dtlItem.lv3Fnsh ? dtlItem.lv3Fnsh : '-' }}</span></strong></div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <!-- content-section:진행중 연수 -->
      <template v-if="isReady">
        <div>
          <article class="content-section" style="padding: 10px 10px 0px 16px">
            <header class="section-header">
              <h4 class="title">진행 및 예정된 연수<span class="count"> 총 {{ renderItems.length }}건</span></h4>
            </header>
          </article>
          <div class="educourse-list-container">
            <!-- educourse-list -->
            <div v-if="renderItems.length === 0" class="search-container">
              <div class="result-empty">
                <img src="@/assets/lxp/images/common/img_empty.png" alt="">
                <p class="text">진행 및 예정된 연수가 없습니다.</p>
              </div>
            </div>
            <ul v-else class="educourse-list">
              <!-- educourse-list > list-item -->
              <li v-for="(item, index) in renderItems" class="list-item" :key="index">
                <!-- educourse-list > educourse -->
                <div class="educourse">
                  <div class="educourse-summary course-row">
                    <article class="course-card">
                      <div class="course-image">
                        <a href="javascript:" class="image-link" @click="goLearn(item)"></a>
                        <figure class="image-figure">
                          <img v-if="item.thumb" :src="getThumbUrl(item.thumb)" alt="">
                          <img v-else-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                          <CourseThumb v-else
                               :num="item.crseMstSn"
                          />
                        </figure>
                      </div>
                      <div class="course-content">
                        <div class="content-header">
                          <h5 class="title">
                            <a href="javascript:" class="title-link" @click="goLearn(item)">{{ item.crseNm }}</a>
                          </h5>
                        </div>
                        <div class="content-footer">
                          <div class="content-meta">
                            <p class="text">{{  item.orgnzNm }}</p>
                            <p class="text">{{timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd')}} - {{timestampToDateFormat(item.endDt, 'yyyy.MM.dd')}}</p>
                          </div>
                          <div class="content-mileage">
                            <!--                      <p v-if="item.splCrseTyCdDcd === '2066015'"  class="text">-</p>-->
                            <p v-if="item.splCrseTyCdDcd === '2066023'" class="text" v-html="getFinishText(item)"></p>
                          </div>
                        </div>
                      </div>
                      <div class="course-actions">&nbsp;</div>
                    </article>
                  </div>
                </div>
              </li>
              <!-- educourse-list > list-item -->
            </ul>
          </div>
        </div>
      </template>

      <article class="content-section" style="padding: 30px 10px 0px 16px">
        <header class="section-header">
          <h4 class="title">연수과정<span class="count"> 총 {{ aplyItems.length }}건</span></h4>
        </header>
      </article>
      <div class="educourse-list-container">
        <div v-if="aplyItems.length === 0" class="search-container">
          <div class="result-empty">
            <img src="@/assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">연수과정이 없습니다.</p>
          </div>
        </div>
        <ul v-else class="educourse-list">
          <li v-for="(item, index) in aplyItems" :key="index" class="list-item">
            <div class="educourse">
              <div class="educourse-summary course-row" style="padding-bottom: 10px;cursor: pointer" @click="collapseOrExpand(index)">
                <article class="course-card">
                  <div class="course-image">
                    <figure class="image-figure">
                      <img v-if="item.thumb" :src="getThumbUrl(item.thumb)" alt="">
                      <CourseThumb
                          v-else
                          :num="item.crseMstSn"
                      />
                    </figure>
                  </div>
                  <div class="course-content">
                    <div class="content-header">
                      <h5 class="title">
                        {{ item.crseNm }}
                      </h5>
                    </div>
                    <div :class="{'content-footer': isMobile}">
                      <div class="content-meta">
                        <span class="text" :class="{'txt_line' : !isMobile, 'txt_multi' : isMobile}" style="color:var(--kb-gold)">{{ item.crseSumup }}</span>
                      </div>
                      <div class="content-meta" style="margin-top: 20px">
                        <span class="text">
                          {{ item.orgnzNm }}
                          {{ item.totLrnPerid ? ' | '.concat(item.totLrnPerid,'일') : '' }}
                          {{ item.crseMlg ? ' | 마일리지'.concat(item.crseMstMlg) : '' }}
                        </span>
                      </div>
                      <div v-if="isMobile" class="striped-list-wrap">
                        <ul class="striped-list">
                          <li class="striped-item">
                            <div class="kb-collapse" :class="{ 'is-active' : !showDists[index] }">
                              <div class="kb-collapse-toggle striped-row" style="background-color: white">
                                <div class="striped-column column-arrow" style="padding: 0px 0px;min-height: 0px"><i class="icon-arrow"></i></div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </article>
              </div>

              <template v-if="showDists[index]">
                <template v-if="item.crseAplyDomains && item.crseAplyDomains.length > 0">
                  <template v-for="(dist, idx) in item.crseAplyDomains[0].crseDists" :key="idx">
                    <template v-if="isShowApply(dist)">
                      <div class="educourse-content" style="border: 0px">
                        <div class="educourse-summary">
                          <p class="title">{{ dist.crseNm }}</p>
                          <p class="status text-red">
                            <span> 정원 {{dist.peoplCnt > 0 ? `${numberComma(dist.peoplCnt)}명` : '-'}} </span>
                            <span v-if="limitOver(dist)"> ・ 정원초과</span>
                          </p>
                        </div>
                        <div class="educourse-date">
                          <div class="date-item">
                            <div class="title">연수기간</div>
                            <div> {{ timestampToDateFormat(dist.bgngDt, 'yyyy.MM.dd') }} - {{ timestampToDateFormat(dist.endDt, 'yyyy.MM.dd') }} </div>
                          </div>
                          <div class="date-item">
                              <div class="title">신청기간</div>
                              <div> {{ timestampToDateFormat(dist.aplyBgngDt, 'yyyy.MM.dd') }} - {{ timestampToDateFormat(dist.aplyEndDt, 'yyyy.MM.dd') }} </div>
                          </div>
                        </div>
                        <div class="educourse-actions">
                          <button class="kb-btn-apply" @click="showApplyViewMobile(dist.distCrseSn)">연수신청</button>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <div class="result-empty">
                    <img src="@/assets/lxp/mobile/images/_common/img_empty.png">
                    <p class="text">수강신청 가능한 차수가 없습니다.</p>
                  </div>
                </template>
              </template>
            </div>
          </li>
        </ul>
      </div>
      <article class="content-section" style="padding: 30px 10px 0px 16px">
      <header class="section-header">
          <h4 class="title">자료실<span class="count"> 총 {{ fileItems.length }}건</span></h4>
      </header>
      </article>
      <div v-if="fileItems.length === 0" class="search-result">
        <!-- result-empty -->
        <div class="result-empty">
          <img src="@/assets/lxp/mobile/images/_common/img_empty.png">
          <p class="text">검색결과가 없어요</p>
        </div>
      </div>

      <div v-else class="notice-list-container">
        <div class="board-list-container">
          <ul class="board-list">
            <li class="board-list-item" v-for="(item, idx) in fileItems" :key="idx">
              <router-link :to="{ name: 'LearnBulletinView', params: {mode : 'view', lrnPostSn: item.lrnPostSn} }" class="board-link"></router-link>
              <div class="list-content">
                <div class="list-content-title">
                  <h5 class="title">
                    <span class="text text-truncate">{{item.title}}</span>
                    <i v-if="item.newLib === 'Y'" class="icon-new ms-1"></i>
                  </h5>
                </div>
                <div class="list-meta-content">
                  <span class="text">{{timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}}</span>
                  <span class="text">{{item.inqCnt}} 조회</span>
                </div>
              </div>
              <div class="board-column column-file" v-if="item.postFileAtchYn === 'Y'"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>
            </li>
          </ul>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <MobileTrainDigitalFileAtch v-if="showAttachments" v-model:showAttachments="showAttachments" :fileItems="files"/>
</template>

<script>

import CourseThumb from '@/components/apply/CourseThumb.vue';
import MobileTrainDigitalFileAtch from "@/views/pages/train/mobile/MobileTrainDigitalFileAtch";
import {trainStarLinkSetup} from "@/assets/js/modules/train/train-star-link-setup";
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";

export default {
  name: 'MobileTrainDigital',
  components: {CommonPaginationFront, CourseThumb, MobileTrainDigitalFileAtch},
  setup: trainStarLinkSetup
}
</script>

<style scoped>


</style>