import {computed, reactive, ref, watch} from 'vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
  ACT_GET_CRSE_DIST_STAR_LINK_ENROLLED_LIST,
  ACT_GET_STAR_LINK_APPLY_LIST,
  ACT_GET_STAR_LINK_DTL,
  ACT_GET_STAR_LINK_FILE_LIST,
  ACT_INSERT_CRSE_DIST_REGULAR
} from '@/store/modules/course/course';
import {getListFunc} from '@/assets/js/ui.init';
import {sortCourseItems} from '@/assets/js/modules/learn/learn-regular-sort';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  commonDownloadFile,
  getItems,
  getPaging,
  initPaging,
  isSuccess,
  lengthCheck,
  numberComma,
  setParamsByQueryString,
  timestampToDateFormat
} from '@/assets/js/util';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';
import navigationUtils from '@/assets/js/navigationUtils';

export const trainStarLinkSetup = () => {
  const isMobile = navigationUtils.any();
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const {showConfirm, showMessage} = useAlert();
  const {showLoading, hideLoading} = useAlert();
  const currentYear = new Date().getFullYear();
  const session = computed(() => store.state.auth.session);

  const isReady = ref(false);
  const statusToggle = ref(false);
  const items = ref([]);
  const nowitems = ref([]);
  const renderItems = ref([]);
  const aplyItems = ref([]);
  const dtlItems = ref([]);
  const dtlItem = reactive({
    lv0Fnsh: '-',
    lv1Fnsh: '-',
    lv2Fnsh: '-',
    lv3Fnsh: '-'
  });

  const search = reactive({
    title: ''
  });
  const paging = ref(initPaging(route));

  const fileItems = ref([]);

  const learns = ref([]);
  const isReady2 = ref(false);

  const currentTs = new Date().getTime();
  const showDists = ref([]);
  const showDistsSize = ref(10);
  const hToggle = ref(false);

  const showStatus = () => {
    statusToggle.value = true;
  };
  const showAttachments = ref(false);

  showLoading();

  getListFunc(`course/${ACT_GET_CRSE_DIST_STAR_LINK_ENROLLED_LIST}`,
      {bgngYmd: `${currentYear}-01-01`, endYmd: `${(currentYear + 1)}-01-01`}, items, null, () => {
        renderItems.value = items.value.map(x => (
            {
              ...x,
              title: '스타링크 아카데미',
              items: sortCourseItems(x.splCrseTyCdDcd, items.value.filter(y => x.splCrseTyCdDcd === y.splCrseTyCdDcd)),
            }
        ));
        hideLoading();
        isReady.value = true;
      });

  getListFunc(`course/${ACT_GET_STAR_LINK_APPLY_LIST}`, {}, aplyItems, null, () => {});
  getListFunc(`course/${ACT_GET_STAR_LINK_DTL}`, {}, dtlItems, () => {},() => {
    dtlItems.value.forEach(x => {
      if(x.crseMstSn === 15185 && x.fnshYn === 'Y') dtlItem.lv0Fnsh='수료';
      if(x.crseMstSn === 100033 && x.fnshYn === 'Y') dtlItem.lv1Fnsh='수료';
      if(x.crseMstSn === 15870 && x.fnshYn === 'Y') dtlItem.lv2Fnsh='수료';
      if(x.crseMstSn === 100034 && x.fnshYn === 'Y') dtlItem.lv3Fnsh='수료';
      if(x.crseMstSn === 15185 && x.fnshYn === 'N') dtlItem.lv0Fnsh='미수료';
      if(x.crseMstSn === 100033 && x.fnshYn === 'N') dtlItem.lv1Fnsh='미수료';
      if(x.crseMstSn === 15870 && x.fnshYn === 'N') dtlItem.lv2Fnsh='미수료';
      if(x.crseMstSn === 100034 && x.fnshYn === 'N') dtlItem.lv3Fnsh='미수료';
    })
  });

  const nowDt = computed(() => timestampToDateFormat(new Date(), 'yyyy-MM-dd'));
  const getStarLinkFiles = () => {
    store.dispatch(`course/${ACT_GET_STAR_LINK_FILE_LIST}`, {
      title: search.title,
      pageNo: paging.value.pageNo,
      pageSize: paging.value.pageSize
    }).then(res => {
      if (lengthCheck(res)) {
        fileItems.value = getItems(res);
        // 새 게시물인지 날짜로 비교
        fileItems.value.map((x) => {
          let diff = new Date(nowDt.value) - new Date(timestampToDateFormat(x.regDt, 'yyyy-MM-dd'));
          let month = new Date(diff).getMonth() > 0 ? new Date(diff).getMonth() * 30 : 0;
          let date = new Date(diff).getDate();
          if(month + date <= 7) x.newLib = 'Y';
          else x.newLib = 'N';
        })
        paging.value = getPaging(res);
      } else {
        fileItems.value = [];
        paging.value.totalCount = 0;
      }
    }).catch(e => {
      console.error(e);
    })
  }

  getStarLinkFiles();

  const pagingFunc = (pageNo, pageSize) => {
    const query = {
      pageNo: pageNo,
      title: search.title
    };
    if (pageSize !== paging.value.pageSize) {
      query.pageSize = pageSize;
    }
    router.push({query: query});
  };

  const getDateFormat = (format, value) => {
    if(value){
      return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
    }
    return '-';
  };

  const clickSearch = () => {
    pagingFunc(1, 10);
  };

  watch(() => route.query, () => {
    setParamsByQueryString(route, 'TrainStarLink', paging, search, true);
    getStarLinkFiles();
  });

  const isSearchAreaShow = ref(false);
  const searchRslt = ref(0);

  watch(() => isSearchAreaShow.value, () => {
    if(isSearchAreaShow.value){
      items.value = [];
      paging.value.totalCount = 0;
    }else{
      search.title = '';
      searchRslt.value = 0;
      if(Object.keys(route.query).length === 0) getStarLinkFiles();
      else pagingFunc();
    }
  })

  const isLoading = ref(false);
  const enrollCourse = (item) => {
    if (isLoading.value) return;
    isLoading.value = true;

    store.dispatch(`course/${ACT_INSERT_CRSE_DIST_REGULAR}`, item.distCrseSn).then(res => {
      if (isSuccess(res)) {
        router.push({name: 'LearnRegularMain', params: {distCrseSn: item.distCrseSn}});
      } else {
        showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        isLoading.value = false;
      }
    }).catch(e => {
      console.error(e);
      showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
      isLoading.value = false;
    });
  };

  const goLearn = (item) => {
    if (item.lrnTrgtGrpDtlSn > 0) {
      router.push({name: 'LearnRegularMain', params: {distCrseSn: item.distCrseSn}});
    } else {
      showConfirm({
        title: item.crseNm,
        text: '해당 연수에 입과하시겠습니까?',
        callback: () => {
          enrollCourse(item);
        },
      });
    }
  };

  const getFinishText = (item) => {
    if (item.lrnTrgtGrpDtlSn > 0 && item.learns.length > 0) {
      return item.fnshYn === 'Y' ? '<span class="text-primary">완료</span>' : '<span class="text-muted">미완료</span>';
    }
    return '<span class="text-muted">미완료</span>';
  };

  const limitOver = (dist) => {
    return (dist.peoplCnt > 0 && dist.peoplCnt <= dist.aplyRegCnt);
  };

  const isShowApply = (dist) => {
    return (dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
  };

  const showApplyViewMobile = (key) => {
    router.push({name: 'ApplyTrainView', params: {distCrseSn: key}});
  };

  const initShow = () => {
    showDists.value = [];
    for (let i = 0; i < showDistsSize.value; i++) {
      showDists.value[i] = false;
    }
  }

  const collapseOrExpand = (index) => {
    showDists.value[index] = !showDists.value[index];
    return showDists.value[index];
  }

  initShow();

  const headerToggle = () => {
    if (hToggle.value) {
      hToggle.value = false;
    } else {
      hToggle.value = true;
    }
  };

  const showApplyView = (index) => {
    router.push({name: 'ApplyTrainView', params: {distCrseSn: index}});
  }

  const downloadAtch = (atch) => {
    commonDownloadFile(atch);
  };

  return {
    isReady,
    isReady2,
    statusToggle,
    items,
    nowitems,
    renderItems,
    showStatus,
    aplyItems,
    dtlItems,
    dtlItem,
    learns,
    session,

    fileItems,
    search,
    paging,
    isSearchAreaShow,
    searchRslt,
    getStarLinkFiles,
    clickSearch,
    pagingFunc,
    getDateFormat,
    timestampToDateFormat,

    goLearn,
    numberComma,
    getThumbUrl,
    getFinishText,
    limitOver,

    isMobile,
    isShowApply,
    showApplyViewMobile,
    collapseOrExpand,
    showDists,
    hToggle,
    headerToggle,
    showAttachments,
    downloadAtch,
    showApplyView,
  };
}
